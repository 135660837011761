import { MapSliceTypeToComponent } from '~/components/SliceManager'

import universalsSlices from './universal_page'

/**
 * Homepage map slice components imports
 */
const mapSliceToComponent: MapSliceTypeToComponent = {
  ...universalsSlices,
}

export default mapSliceToComponent
