import mapSliceTypeToComponent from '~/imports/home_page'

import SliceManager from '~/components/SliceManager'

import { HOMEPAGE } from '~/data/fetch-links'
import { getStaticPropsFunction } from '~/data/page-data'

function HomePage({ slices }) {
  return (
    <SliceManager
      mapSliceTypeToComponent={mapSliceTypeToComponent}
      slicesData={slices}
    />
  )
}

export default HomePage

export const getStaticProps = getStaticPropsFunction({
  type: 'home-page',
  fetchLinks: HOMEPAGE,
})
